<template>
    <client-page>

        <sub-visual sh="해외사업" tabActive="교육지원" bg="/images/sub/visual/sv2.jpg" />

        <section class="section">
            <v-container>

                <business-item tit="학교운영지원" sc="배움의 기회가 없는 아동들을 위해 학교를 설립하고, 지속적으로 공부를 할 수 있도록 지원합니다." file="school">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            학교 설립 및 운영 : 기초교육 환경이 조성되어있지 않은 지역에 정부가 승인한 공교육 학교를 설립하고, 학교 운영에 필요한 자금과 물품 등을 지원
                        </li>
                        <li class="dot-text">
                            문해교실 운영 : 글을 읽지 못해 사회적·경제적으로 자립이 어려운 아프리카 여성을 대상으로 문해교실 운영
                        </li>
                        <li class="dot-text">
                            교재 및 학용품 지원 : 모든 아이들이 차별 없이 수업에 참여하고, 학업을 지속적으로 이어갈 수 있도록 교재 · 학용품 · 가방 · 교복 등을 지원
                        </li>
                    </ul>
                </business-item> 

                <business-item tit="교육시설 개보수" sc="낙후된 교육시설로 제대로 된 수업을 받지 못하는 저개발국가 아동들에게 필요한 시설을 지원합니다." file="eduf">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            도서관 및 IT 교실 지원 : 글로벌 인재로의 성장을 위한 도서관과 IT교실 및 컴퓨터 지원
                        </li>
                        <li class="dot-text">
                            교육환경 개선 : 아동들이 안전하게 학교생활을 할 수 있도록 화장실·기숙사·체육관 등 낙후된 시설 개보수 진행
                        </li>
                    </ul>
                </business-item> 

            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from '@/components/client/sub/sub-visual.vue';
import BusinessItem from '@/components/client/sub/business-item.vue';
export default {
    components: {
        ClientPage,
        SubVisual,
        BusinessItem,
    },
};
</script>